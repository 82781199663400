<script setup>
import { ref, onBeforeMount } from 'vue'

// composables
import useCharging from '@/composables/charging/useCharging'

// components
import Footer from '@/components/Footer.vue'
import Btn from '@/components/inputs/Btn.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import OTCard from '@/pages/charging/components/OTCard.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'
import SettingPanel from '@/pages/home/components/SettingPanel.vue'
import StartScreen from '@/pages/charging/components/StartScreen.vue'
import ChargingStatus from '@/pages/charging/components/ChargingStatus.vue'
import ChargingDetails from '@/pages/charging/components/ChargingDetails.vue'
import SessionEndedScreen from '@/pages/charging/components/SessionEndedScreen.vue'
import InitiatingConnection from '@/pages/charging/components/InitiatingConnection.vue'
import ConnectorNotFound from '@/pages/charging/components/ConnectorNotFound.vue'
import SessionSummary from '@/pages/charging/components/SessionSummary.vue'

// translations
const { t } = useI18n()
import { useI18n } from 'vue-i18n'

const {
    isLoggedIn,
    pages,
    connectorNotFound,
    hasConnectorData,
    chargingDetails,
    fetchingOTChargingSummary,
    fetchingData,
    attrs,
    activeScreen,
    init
} = useCharging()

const isMobileMenuVisible = ref(false)

onBeforeMount(async () => {
    await init()
})
</script>

<template>
    <div class="page">
        <div class="page__container">
            <menu :class="{ 'page__mobile-menu': isMobileMenuVisible }" class="page__menu">
                <SidebarMenu
                    v-if="isLoggedIn"
                    :is-active="isMobileMenuVisible"
                    @closeMenu="isMobileMenuVisible = false"
                />
            </menu>
            <main :class="{ page__main: true, 'page__main--shadow': isMobileMenuVisible, 'logged-out': !isLoggedIn }">
                <header ref="header" class="page__header">
                    <template v-if="isLoggedIn">
                        <button class="mobile-menu-btn" @click="isMobileMenuVisible = true">
                            <v-icon>mdi-menu</v-icon>
                        </button>
                        <Btn
                            v-if="pages.includes($route.name)"
                            :ripple="false"
                            class="text-none page__header-back active-button active-icon"
                            color="var(--v-color-gray)"
                            text
                            @click="goBack"
                        >
                            <v-icon class="mr-2"> mdi-arrow-left </v-icon>
                            <span class="page__header-back--text">{{ t('BtnGoBack.Back') }}</span>
                        </Btn>
                    </template>
                    <LangSwitcher class="ml-auto" />
                    <SettingPanel v-if="isLoggedIn" />
                </header>
                <section class="page__content d-flex justify-center align-center v-row">
                    <v-col cols="12" md="4">
                        <v-skeleton-loader
                            v-if="activeScreen === null && !connectorNotFound"
                            class="flex-column"
                            type="avatar, article, actions"
                            v-bind="attrs"
                        />
                        <StartScreen
                            v-if="activeScreen === 0 && hasConnectorData && !fetchingData && !fetchingOTChargingSummary"
                        />
                        <InitiatingConnection v-if="activeScreen === 1" />
                        <ChargingStatus v-if="activeScreen === 2" />
                        <ChargingDetails v-if="activeScreen === 3 && chargingDetails !== null" />
                        <SessionEndedScreen v-if="activeScreen === 4 || fetchingOTChargingSummary" />
                        <SessionSummary v-if="activeScreen === 5" />
                        <OTCard v-if="activeScreen === 6" />
                        <ConnectorNotFound v-if="connectorNotFound" />
                    </v-col>
                </section>
                <Footer />
            </main>
        </div>
        <div class="modal-backdrop" @click="isMobileMenuVisible = false" />
    </div>
</template>
