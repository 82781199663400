<script setup>
import { reactive } from 'vue'
import store from '@/store'

// configs
import { Lang } from '@/configs'

// composables
import useWindowWidth from '@/composables/windowWidth.js'
import usePriceProgram from '@/composables/registration/usePriceProgram'
import MeterWithInfo from '@/pages/drivers/components/helpers/MeterWithInfo.vue'

// utils
import formatDecimal from '@/utils/formatDecimal'
import markdownParser from '@/utils/markdownParser'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { state } = store
const { windowWidth } = useWindowWidth()
const { tariffs, userTariffData, selectedTariff, getCurrency, locale, loading, clickedIndex, next } = usePriceProgram()

const attrs = reactive({
    class: 'mb-6',
    elevation: 1
})
</script>

<template>
    <div class="tariffs">
        <template v-if="!tariffs.length">
            <v-slide-group no-gutters class="row no-gutters mb-6">
                <v-slide-group-item v-for="n in 3" :key="n">
                    <v-card class="skeleton-wrapper col">
                        <v-skeleton-loader
                            v-bind="attrs"
                            type="article, card-avatar, actions, list-item-avatar-three-line"
                            class="skeleton-tariff"
                        />
                    </v-card>
                </v-slide-group-item>
            </v-slide-group>
        </template>
        <template v-else>
            <v-slide-group
                v-model="selectedTariff"
                selected-class="selected-tariff"
                class="mb-6"
                :show-arrows="true"
                mobile-breakpoint="sm"
            >
                <v-slide-group-item
                    v-for="(tariff, index) in tariffs"
                    v-slot="{ isSelected, toggle, selectedClass }"
                    :key="tariff.id"
                    :value="tariff"
                >
                    <v-card
                        elevation="0"
                        class="col tariff registration pa-0"
                        width="340px"
                        :class="['ma-2', selectedClass]"
                    >
                        <template v-if="tariff.priceList.extraKeys.ribbon">
                            <div class="ribbon text-caption text-center text-white pa-1">
                                {{ tariff.priceList.extraKeys.ribbon }}
                            </div>
                        </template>
                        <h2 class="text-h6 text-uppercase text-center px-4 py-6">
                            {{ tariff.name }}
                        </h2>
                        <template v-if="tariff.priceList.freeKwh !== null">
                            <h3 class="free-kwhs text-subtitle-1 text-center">
                                {{ t('registrationWizard.freeKWhs') }}
                            </h3>
                            <h2 class="text-h4 text-center font-weight-light mb-4">
                                {{ tariff.priceList.freeKwh }} kWh
                            </h2>
                        </template>
                        <v-divider class="pb-6" :dark="isSelected" />
                        <h3 class="text-subtitle-1 text-center mb-2">
                            {{ t('registrationWizard.charging') }}
                        </h3>
                        <v-row no-gutters class="metters justify-center py-8">
                            <v-col class="d-flex flex-column justify-start align-center">
                                <MeterWithInfo
                                    class="box"
                                    :price-list="tariff.priceList.AC[0]"
                                    :isTariffSelected="isSelected"
                                    :currency="getCurrency"
                                    :show-power="tariff.priceList.DC.length > 1"
                                />
                            </v-col>
                            <v-col class="d-flex flex-column justify-start align-center">
                                <MeterWithInfo
                                    class="box"
                                    :price-list="tariff.priceList.DC[0]"
                                    :isTariffSelected="isSelected"
                                    :currency="getCurrency"
                                    :show-power="tariff.priceList.DC.length > 1"
                                />
                            </v-col>
                            <v-col
                                v-if="tariff.priceList.DC.length > 1"
                                class="d-flex flex-column justify-start align-center"
                            >
                                <MeterWithInfo
                                    class="box"
                                    :price-list="tariff.priceList.DC[1]"
                                    :isTariffSelected="isSelected"
                                    :currency="getCurrency"
                                    :show-power="tariff.priceList.DC.length > 1"
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="pa-5">
                            <v-col @click="toggle">
                                <v-btn
                                    rounded="xl"
                                    large
                                    class="next-button text-none"
                                    :disabled="loading"
                                    :loading="loading && clickedIndex === index"
                                    :color="isSelected ? 'rgb(var(--v-theme-white))' : 'primary'"
                                    :data-testid="`tariff-${tariff.name}`"
                                    @click="next(tariff.id, index)"
                                >
                                    <template v-if="locale !== Lang.pl">
                                        <template v-if="tariff.priceList.priceOff">
                                            <div
                                                v-html="
                                                    markdownParser(
                                                        tariff.priceList.priceOff.buttonTitle[state.account.lang]
                                                    )
                                                "
                                            />
                                        </template>
                                        <template v-else>
                                            {{
                                                t('registrationWizard.selectForMonthlyPrice', {
                                                    price: tariff.monthlyPrice ? formatDecimal(tariff.monthlyPrice) : 0,
                                                    currency: getCurrency
                                                })
                                            }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="tariff.priceList.priceOff">
                                            <div
                                                v-html="
                                                    markdownParser(
                                                        tariff.priceList.priceOff.buttonTitle[state.account.lang]
                                                    )
                                                "
                                            />
                                        </template>
                                        <template v-else>
                                            {{
                                                t('registrationWizard.selectForMonthlyPrice', {
                                                    price: tariff.monthlyPrice ? formatDecimal(tariff.monthlyPrice) : 0,
                                                    currency: getCurrency
                                                })
                                            }}
                                        </template>
                                    </template>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <template v-if="tariff.priceList.priceOff && tariff.priceList.priceOff.tariffDescription">
                            <v-divider :dark="isSelected" />
                            <v-row no-gutters class="priceOffDescription pa-4">
                                <v-col>
                                    <div v-html="markdownParser(t(`${tariff.priceList.priceOff.tariffDescription}`))" />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-if="tariff.priceList.extraKeys">
                            <v-divider :dark="isSelected" />
                            <v-row no-gutters class="pa-4 text-center">
                                <v-col>
                                    <h4 class="text-body-1 font-weight-medium" data-testid="roamingTitle">
                                        {{ tariff.priceList.extraKeys.title }}
                                    </h4>
                                    <h5 class="mb-2">{{ tariff.priceList.extraKeys.subtitle }}</h5>
                                    <p class="text-caption mb-2" data-testid="roamingText">
                                        {{ tariff.priceList.extraKeys.content }}
                                    </p>
                                    <p class="text-caption">{{ tariff.priceList.extraKeys.mainBenefits }}</p>
                                </v-col>
                            </v-row>
                        </template>
                    </v-card>
                </v-slide-group-item>
            </v-slide-group>
        </template>
    </div>
</template>
