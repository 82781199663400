<script setup>
import { ref, reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store'

// components
import Btn from '@/components/inputs/Btn.vue'
import Logo from '@/components/icons/Logo.vue'
import Modal from '@/components/modals/Modal.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'

// utils
import { useI18n } from 'vue-i18n'
import showMessage from '@/utils/errorMessage.js'

// validations
import useValidations from '@/composables/useValidations'

const { isValidEmail, isNotEmpty } = useValidations()

const emit = defineEmits(['cancel'])

const { t } = useI18n()
const { dispatch } = store
const router = useRouter()

const state = reactive({
    rules: [isValidEmail, isNotEmpty],
    email: store.state.account.email,
    formErrorMessage: '',
    isSend: false,
    recaptchaToken: '',
    isFormValid: false
})

const recaptcha = ref(null)
const form = ref()

const siteKey = computed(() => store.state.settings.recaptchaSiteKey)

const buttonDisabled = computed(() => {
    if (recaptcha.value) {
        return !state.isSend && siteKey.value && state.recaptchaToken && state.isFormValid ? false : true
    }
    return !state.isSend && state.isFormValid ? false : true
})

const resetPassword = () => {
    if (state.isFormValid) {
        const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
        grecaptcha.enterprise.ready(async () => {
            const recaptchaToken = await grecaptcha.enterprise.execute(recaptchaSiteKey, { action: 'forgot_password' })

            dispatch('resetPassword/sendEmail', {
                email: state.email,
                recaptchaToken: recaptchaToken
            })
                .then(() => {
                    state.isSend = true
                })
                .catch((message) => {
                    showMessage(message)
                    state.recaptchaToken = null
                })
        })
    }
}
</script>

<script>
export default {
    name: 'ResetPassword'
}
</script>

<template>
    <section class="reset-password__main pa-4 pa-md-6">
        <header class="reset-password__header">
            <Logo height="37px" width="184px" />
            <LangSwitcher class="custom-switch-position" />
        </header>
        <main class="reset-password__form">
            <v-form ref="form" v-model="state.isFormValid" class="form">
                <h1 class="text-h5 mb-6">
                    {{ t('resetPassword.ForgottenPassword') }}
                </h1>
                <v-text-field
                    v-model="state.email"
                    :error-messages="state.formErrorMessage"
                    :placeholder="`${t('resetPassword.Email')}*`"
                    :rules="state.rules"
                    class="form__input"
                    height="48px"
                    variant="outlined"
                    data-testid="iForgotMail"
                    @keydown.enter.prevent
                />
                <Btn
                    :disabled="buttonDisabled"
                    :loading="store.getters['isLoading']"
                    class="w-100"
                    height="56px"
                    data-testid="resetPasswordBtn"
                    large
                    @click="resetPassword"
                >
                    {{ t('resetPassword.SendPassword') }}
                </Btn>
                <div class="form__footer">
                    {{ t('resetPassword.BackTo') }}
                    <router-link class="form__footer-link" to="/login">
                        {{ t('resetPassword.LogIn') }}
                    </router-link>
                </div>
            </v-form>

            <Modal :is-active="state.isSend" :max-width="450" @cancel="emit('cancel')">
                <div class="modal pa-5">
                    <v-row>
                        <v-col cols="12" class="text-center">
                            {{ t('resetPassword.PasswordResetEmail') }}
                            <br />{{ state.email }} <br />{{ t('resetPassword.CheckYourInbox') }}<br />
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <Btn
                                class="modal__btn mt-4"
                                medium
                                data-testid="resetMailSent"
                                @click="router.push('login')"
                            >
                                OK
                            </Btn>
                        </v-col>
                    </v-row>
                </div>
            </Modal>
        </main>
    </section>
</template>
