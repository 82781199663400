<script setup>
import { onBeforeUpdate, onMounted, reactive, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import store from '@/store'

// icons
import { mdiLogout } from '@mdi/js'

// components
import Footer from '@/components/Footer.vue'
import Btn from '@/components/inputs/Btn.vue'
import Blocked from '@/components/Blocked.vue'
import Modal from '@/components/modals/Modal.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'
import SettingPanel from '@/pages/home/components/SettingPanel.vue'

// Utils
import { getDefaultState } from '@/store/modules/drivers/helper'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const { dispatch, commit, getters } = store

const state = reactive({
    isLocationMap: false,
    showDialogBox: false,
    mdiLogout,
    isMobileMenuVisible: false,
    pages: [
        'add-driver',
        'consumptionDetails',
        'driverDetails',
        'chargerDetails',
        'locationDetails',
        'pricePrograms',
        'driverPriceProgram'
    ]
})

const goBack = (value) => {
    // TODO - Andrej - we need to add everywhere where is button back value
    if (typeof value === 'undefined') {
        switch (route.name) {
            case 'driverDetails':
                commit('drivers/setDriver', getDefaultState().driver)
                router.push({ path: '/drivers' })
                break
            case 'driverPriceProgram':
                window.onbeforeunload = null
                state.showDialogBox = true
                break
            default:
                router.go(-1)
        }
    } else {
        router.go(-1)
    }
}

const resolveUserData = async () => {
    await dispatch('account/getAccount')
    await dispatch('account/getPaymentInfo')
}

onBeforeUpdate(() => {
    state.isLocationMap = window.location.pathname.toLowerCase().indexOf('locationmap') > -1
})

onBeforeMount(async () => {
    if (route.name === undefined && route.path !== '/') {
        router.push({ name: 'pageNotFound' })
        return
    }
    await resolveUserData()

    if (getters['account/registrationSteps'].length) router.push({ name: 'registration-wizard' })
})

onMounted(() => {
    state.isLocationMap = window.location.pathname.toLowerCase().indexOf('locationmap') > -1
    if (!getters['account/isAuth']) {
        router.push('welcome')
        return
    }
})
</script>

<template>
    <div v-if="store.state.account.id" class="home-page-wrapper">
        <Blocked />
        <div class="page__container">
            <menu :class="{ 'page__mobile-menu': state.isMobileMenuVisible }" class="page__menu">
                <SidebarMenu :is-active="state.isMobileMenuVisible" @closeMenu="state.isMobileMenuVisible = false" />
            </menu>
            <main :class="{ page__main: true, 'page__main--shadow': state.isMobileMenuVisible }">
                <header ref="header" class="page__header">
                    <button class="mobile-menu-btn" @click="state.isMobileMenuVisible = true">
                        <v-icon>mdi-menu</v-icon>
                    </button>
                    <Btn
                        v-if="state.pages.includes(route.name)"
                        :ripple="false"
                        class="text-none page__header-back active-button active-icon"
                        color="rgb(var(--v-theme-color-gray))"
                        variant="text"
                        @click="goBack"
                    >
                        <v-icon class="mr-2"> mdi-arrow-left </v-icon>
                        <span class="page__header-back--text">{{ t('BtnGoBack.Back') }}</span>
                    </Btn>
                    <lang-switcher class="ml-auto" />
                    <SettingPanel />
                </header>
                <router-view />
                <Footer v-if="!state.isLocationMap" />
            </main>
        </div>
        <!-- has changes dialog box -->
        <Modal :is-active="state.showDialogBox" :max-width="275" @cancel="state.showDialogBox = false">
            <div class="modal">
                <div class="pa-5">
                    <h4 class="py-5 mb-5 text-center">
                        {{ t('error.HasChanges') }}
                    </h4>
                    <div class="d-flex justify-space-around">
                        <Btn class="modal__btn" medium @click="state.showDialogBox = false">
                            {{ t('oAuth.cancel') }}
                        </Btn>
                        <Btn
                            class="modal__btn active-link active-button"
                            medium
                            variant="text"
                            @click=";[(state.showDialogBox = false), goBack(true)]"
                        >
                            {{ t('BtnGoBack.ok') }}
                        </Btn>
                    </div>
                </div>
            </div>
        </Modal>
        <div class="modal-backdrop" @click="state.isMobileMenuVisible = false" />
    </div>
</template>
