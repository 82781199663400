// Helpers
import { getDefaultState } from './helper'

// Utils
import { RegistrationView } from '@/utils/registrationView'

// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

import moment from 'moment'

export const mutations = {
    authUrlBearer: (state, data) => {
        state.expires_at = data.access_token.expires_at
        state.token = data.access_token.token
        state.roles = data.roles
        state.driver = data.driver
        state.email = data.email
        state.phone = data.phone
        state.firstName = data.first_name
        state.lastName = data.last_name
        state.actionAfterLogin = data['action_after_login']
            ? RegistrationView.showComponent(data['action_after_login'])
            : null
        state.primaryDriverId = data.primary_driver.id
    },
    auth: (state, data) => {
        const DOMAIN = import.meta.env.VITE_APP_COOKIES_DOMAIN
        const expiresAt = moment(data.access_token.expires_at).diff(moment(), 'minutes', false)

        cookies.set('gw_token', data.access_token.token, `${expiresAt}min`, '/', DOMAIN)
        cookies.set('gw_token_expires_at', data.access_token.expires_at, `${expiresAt}min`, '/', DOMAIN)

        state.expires_at = data.access_token.expires_at
        state.token = data.access_token.token
        state.roles = data.roles
        state.driver = data.driver
        state.email = data.email
        state.phone = data.phone
        state.firstName = data.first_name
        state.lastName = data.last_name
        state.actionAfterLogin = data.action_after_login
            ? RegistrationView.showComponent(data.action_after_login)
            : null
        state.primaryDriverId = data.primary_driver_id
    },
    setAccountData: (state, data) => {
        state.homeChargers = data.home_chargers
        state.id = data.id
        state.roles = data.roles
        state.blockedChargingAt = data.blocked_charging_at
        state.phone = data.phone
        state.isPhoneVerified = data.payer.phone_verified
        state.email = data.email
        state.drivers_count = data.payer.drivers_count
        state.isEmailVerified = data.payer.email_verified
        state.firstName = data.first_name
        state.lastName = data.last_name
        state.birthDate = data.payer.birth_date
        state.gender = data.payer.gender?.toLowerCase() || null
        state.communicationLanguage = data.payer.language
        state.isCompany = data.payer.is_company
        state.businessId = data.payer.business_id
        state.companyName = data.payer.company_name
        state.isSelfEmployed = data.payer_type === 'self_employed' || false
        state.typeRegistration = data.payer.type_registration
        state.payerConsents = data.payer.consents
        state.country = data.payer.country
        state.country_iso3 = data.payer.country_iso3
        state.city = data.payer.city
        state.street = data.payer.street
        state.houseNumber = data.payer.house_number
        state.taxId = data.payer.tax_id
        state.vatId = data.payer.vat_id
        state.zipCode = data.payer.zip_code
        state.driver = data.driver
        state.paymentGatewayProvider = data.payment_gateway_provider
        state.primaryDriverId = data.payer.primary_driver_id
        state.actionAfterLogin = data.action_after_login
            ? RegistrationView.showComponent(data.action_after_login)
            : null
        if (data.registration_steps.length) {
            const steps = []
            data.registration_steps.forEach((step) => {
                steps.push(RegistrationView.showComponent(step))
            })
            state.registrationSteps = steps
        } else {
            state.registrationSteps = []
        }
        // this is here because users with delivery address saved from single textarea have now delivery address data in delivery_street
        if (
            data.payer.delivery_street &&
            null === data.delivery_city &&
            null === data.delivery_country &&
            null === data.delivery_house_number &&
            null === data.delivery_name &&
            null === data.delivery_zip_code
        ) {
            state.deliveryAddress = {
                city: '',
                country: '',
                houseNumber: '',
                name: '',
                street: data.delivery_street,
                zipCode: ''
            }
            return
        }
        if (
            data.payer.delivery_address_object &&
            data.payer.delivery_address_object.city &&
            data.payer.delivery_address_object.house_number &&
            data.payer.delivery_address_object.name &&
            data.payer.delivery_address_object.street &&
            data.payer.delivery_address_object.zip_code &&
            data.payer.delivery_address_object.country_iso3
        ) {
            state.deliveryAddress = {
                city: data.payer.delivery_address_object.city,
                country_iso3: data.payer.delivery_address_object.country_iso3,
                houseNumber: data.payer.delivery_address_object.house_number,
                name: data.payer.delivery_address_object.name,
                street: data.payer.delivery_address_object.street,
                zipCode: data.payer.delivery_address_object.zip_code
            }
            return
        }
        state.deliveryAddress = null
    },
    setEmail: (state, email) => {
        state.email = email
    },
    setIsEmailVerified: (state, value) => {
        state.isEmailVerified = value
    },
    setPhone: (state, phone) => {
        state.phone = phone
    },
    setIsPhoneVerified: (state, value) => {
        state.isPhoneVerified = value
    },
    setFieldValue: (state, { field, value }) => {
        state[field] = value
    },
    setFirstName: (state, name) => {
        state.firstName = name
    },
    setLastName: (state, name) => {
        state.lastName = name
    },
    setFullName: (state, data) => {
        state.firstName = data.firstName
        state.lastName = data.lastName
    },
    setBirthDate: (state, value) => {
        state.birthDate = value
    },
    setGender: (state, value) => {
        state.gender = value
    },
    setBlocked: (state, data) => {
        state.isBlocked = data
    },
    setLocale: (state, locale) => {
        state.locale = locale
    },
    setLang: (state, lang) => {
        const DOMAIN = import.meta.env.VITE_APP_COOKIES_DOMAIN
        cookies.set('gw_lang', lang, '400d', '/', DOMAIN)
        state.lang = lang
    },
    setCommunicationLanguage: (state, lang) => {
        state.communicationLanguage = lang
    },
    setCountry: (state, value) => {
        state.country = value
    },
    setCity: (state, value) => {
        state.city = value
    },
    setStreet: (state, value) => {
        state.street = value
    },
    setHouseNumber: (state, value) => {
        state.houseNumber = value
    },
    setZipCode: (state, value) => {
        state.zipCode = value
    },
    setDeliveryAddress: (state, value) => {
        state.deliveryAddress = value
    },
    setBusinessId: (state, value) => {
        state.businessId = value
    },
    setIsCompany: (state, value) => {
        state.isCompany = value
    },
    setCompanyName: (state, value) => {
        state.companyName = value
    },
    setTaxId: (state, value) => {
        state.taxId = value
    },
    setVatId: (state, value) => {
        state.vatId = value
    },
    setIsSelfEmployed: (state, value) => {
        state.isSelfEmployed = value
    },
    setConsent: (state, data) => {
        if (data.value) {
            state.payerConsents.push(data.key)
        } else {
            state.payerConsents = state.payerConsents.filter((item) => item !== data.key)
        }
    },
    setRevocableConsents: (state, payload) => {
        state.revocableConsents = payload
    },
    updateRevocableConsent: (state, payload) => {
        const consentIndex = state.revocableConsents.findIndex((obj) => obj.id === payload.consentId)
        state.revocableConsents[consentIndex].checked = payload.value
    },
    setPrimaryDriverId: (state, id) => {
        state.primaryDriverId = id
    },
    setPaymentInfo: (state, value) => {
        state.paymentInfo = value
    },
    resetStore: (state) => {
        const DOMAIN = import.meta.env.VITE_APP_COOKIES_DOMAIN
        Object.assign(state, getDefaultState())
        cookies.remove('gw_token', '/', DOMAIN)
        cookies.remove('gw_token_expires_at', '/', DOMAIN)
    },
    setBraintreePayload: (state, value) => {
        state.braintreePayload = value
    },
    setActionsAfterLogin: (state, value) => {
        state.actionAfterLogin = value
    },
    setRegistrationSteps: (state, value) => {
        state.registrationSteps = value
    },
    setCookieAccepted: (state, { value, cookiesExpiration }) => {
        Vue.$cookies.set('isCookieAccepted', value, cookiesExpiration)
        state.isCookieAccepted = value
    },
    setNecessaryCookieAccepted: (state, { value, cookiesExpiration }) => {
        Vue.$cookies.set('isNecessaryCookieAccepted', value, cookiesExpiration)
        state.isNecessaryCookieAccepted = value
    },
    setStatisticsCookieAccepted: (state, { value, cookiesExpiration }) => {
        Vue.$cookies.set('isStatisticsCookieAccepted', value, cookiesExpiration)
        state.isStatisticsCookieAccepted = value
    },
    setRememberMe: (state, value) => {
        state.remember_me = value
    },
    setPersonalDataIsEditView: (state, value) => {
        state.personalDataIsEditView = value
    },
    setIsEUMemberState: (state, value) => {
        state.isEUMemberState = value
    },
    setNotifyAfterSession: (state, value) => {
        state.notifyAfterSession = value
    }
}
