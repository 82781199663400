<script setup>
import moment from 'moment'
import {useStore} from 'vuex'
import { computed, reactive } from 'vue'

// store
const store = useStore()
const { dispatch } = store

// components
import Modal from '@/components/modals/Modal.vue'
import FreeKwhPackage from '@/components/FreeKwhPackage.vue'

// utils
import {roundToPrecision} from '@/utils'
import formatDecimal from '@/utils/formatDecimal'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const state = reactive({
    isDialogActive: false
})

const driver = computed(() => {
    return store.state.drivers.driver
})

const availableAmount = computed(() => {
    const freeKWh = driver.value.freeKWh
    if(freeKWh) {
        return (0.75 - (( freeKWh.used_amount / freeKWh.freekwh_amount) * 0.75)) * 100
    }
    return 0
})

const getFreeKWh = (freeKWh) => {
    const result = parseFloat(freeKWh.freekwh_amount) - parseFloat(freeKWh.used_amount)
    return result >= 0 ? result : 0
}

const formatFreeKWh = (freeKWh) => {
    return roundToPrecision(parseFloat(freeKWh), 2)
}

const getFreeKWhPerDriver = async () => {
    return dispatch('freeKwhPackages/getFreeKWhPerDriver')
}

const freeKwhPerDriver = computed(() => {
    return store.state.freeKwhPackages?.freeKwhPerDriver || []
})

const freeKwhList = computed(() => {
    return freeKwhPerDriver.value?.filter(el => el.driver_id === driver.value.id)
})

getFreeKWhPerDriver()
</script>

<template>
    <section class="free-kwh-wrapper">
        <v-row>
            <v-col cols="12" class="pt-0 mb-3">
                <h2 class="home-page-title">
                    {{ t('freeKwh.freeKwh') }}
                </h2>
            </v-col>
        </v-row>
        <v-card elevation="0"
                class="free-kwh-card d-flex align-items-center justify-center"
        >
            <v-container fluid>
                <v-row v-if="driver.freeKWh.freekwh_amount">
                    <v-col cols="12"
                           class="d-flex align-items-center justify-center pt-8 pb-0"
                    >
	                    <v-progress-circular
		                    class="circular-progress-under"
		                    :rotate="225"
		                    :size="200"
		                    :width="5"
		                    :model-value="75"
		                    color="rgb(var(--v-theme-secondary-darken-1))"
	                    />
                        <v-progress-circular
                            class="circular-progress"
                            :rotate="225"
                            :size="200"
                            :width="5"
                            :model-value="availableAmount"
                            color="primary"
                        >
                            <div class="text-wrapper text-center">
                                <strong class="used-kwh">
                                    {{ formatDecimal(formatFreeKWh(getFreeKWh(driver.freeKWh))) }}
                                </strong>
                                <div class="info-text pt-1">
                                    <span class="text-black">{{ t('freeKwh.of') }} {{ driver.freeKWh.freekwh_amount }} {{ driver.freeKWh.kwh }}</span>
                                </div>
                            </div>
                        </v-progress-circular>
                    </v-col>
                    <v-col cols="12"
                           class="d-flex align-items-center justify-center pt-2 pb-8"
                    >
                        <v-btn
                            color="primary"
                            class="font-weight-regular text-none"
                            @click="state.isDialogActive = true"
                        >
                            {{ t('freeKwh.showDetails') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else class="h-100 align-items-center">
                    <v-col cols="12">
                        <div class="d-flex align-items-center justify-center">
                            <h3 class="text--disabled">
                                {{ t("error.NoDataAvailable") }}
                            </h3>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <Modal :is-active="state.isDialogActive"
               :max-width="650"
               @cancel="state.isDialogActive = false"
        >
            <div class="pa-5">
                <FreeKwhPackage :free-kwh="freeKwhList"
                                :driver-id="driver.id"
                />
            </div>
        </Modal>
    </section>
</template>