<script setup>
import { reactive, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

// components
import CountrySelect from '@/components/registrationWizard/CountrySelect.vue'

// validations
import useValidations from '@/composables/useValidations'
const {
    isNotEmpty,
    isValid2Characters,
    isValidSlovakTax,
    isValidCroatianTax,
    isValidSlovakVat,
    isValidPolishVat,
    isValidCroatianVat,
    isValidPolishCompany,
    isValidSlovakCompany,
    isValidCroatianCompany,
    maxCharsExceeded
} = useValidations()

// utils
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessage'
import showMessageArray from '@/utils/errorMessageArray'
import { CountryISO } from '@/configs'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()
const { commit, dispatch, getters } = store

const emit = defineEmits(['setFormField'])

const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
})

const state = reactive({
    isVatPayer: !!getters['account/vatId'],
    currentBusinessId: store.state.account.businessId,
    canValidateVat: false,
    errors: {
        business_id: ''
    }
})

const businessId = computed({
    get() {
        return props.formData.businessId
    },
    set(value) {
        setValue('businessId', value)
    }
})
const companyName = computed({
    get() {
        return props.formData.companyName
    },
    set(value) {
        setValue('companyName', value)
    }
})
const taxId = computed({
    get() {
        return props.formData.taxId
    },
    set(value) {
        setValue('taxId', value)
    }
})
const vatId = computed({
    get() {
        return getters['account/vatId']
    },
    set(value) {
        state.canValidateVat = value.length > 6 ? true : false
        commit('account/setVatId', value)
        setValue('vatId', value)
    }
})
const isSelfEmployed = computed({
    get() {
        return props.formData.isSelfEmployed
    },
    set(value) {
        setValue('isSelfEmployed', value)
        commit('account/setIsSelfEmployed', value)
    }
})
const isPolishCompany = computed(() => {
    return props.formData.country_iso3 === CountryISO.Poland
})
const isSlovakCompany = computed(() => {
    return props.formData.country_iso3 === CountryISO.Slovakia
})
const isCroatianCompany = computed(() => {
    return props.formData.country_iso3 === CountryISO.Croatia
})
const companyIdPlaceholder = computed(() => {
    if (isPolishCompany.value) return 'NIP'
    if (isSlovakCompany.value) return 'ICO'
    if (isCroatianCompany.value) return 'OIB'
    return 'CompanyBusinessId'
})
const companyTaxIdPlaceholder = computed(() => {
    if (isCroatianCompany.value) return 'MBS'
    return 'TaxId'
})

watch(
    () => state.isVatPayer,
    (val) => {
        emit('isVatPayer', val)
        if (val) {
            setValue('vatId', vatId.value)
            return
        }
        setValue('vatId', null)
    }
)

const isValidCompanyId = (v) => {
    if (isSlovakCompany.value) return isValidSlovakCompany(v)
    if (isPolishCompany.value) return isValidPolishCompany(v)
    if (isCroatianCompany.value) return isValidCroatianCompany(v)
    return isValid2Characters(v)
}
const isValidVat = (v) => {
    if (isPolishCompany.value) return isValidPolishVat(v)
    if (isSlovakCompany.value) return isValidSlovakVat(v)
    if (isCroatianCompany.value) return isValidCroatianVat(v)
    return isValid2Characters(v)
}
const isValidTax = (v) => {
    if (isSlovakCompany.value) return isValidSlovakTax(v)
    if (isCroatianCompany.value) return isValidCroatianTax(v)
    return isValid2Characters(v)
}
const isLoading = computed(() => {
    return getters['isLoading']
})

const setValue = (field, value) => {
    emit('setFormField', { field, value })
}
const setData = (data) => {
    if (data.vat_id) {
        state.isVatPayer = true
        setValue('vatId', data.vat_id)
        commit('account/setVatId', data.vat_id)
    }
    if (data.company_name) setValue('companyName', data.company_name)
    if (data.tax_id) setValue('taxId', data.tax_id)
    if (data.city) setValue('city', data.city)

    setValue('isSelfEmployed', data.is_self_employed)
    isSelfEmployed.value = data.is_self_employed
    commit('account/setIsSelfEmployed', data.is_self_employed)

    if (data.zip_code) {
        if (props.formData.country_iso3 === CountryISO.Poland) {
            // parse zip code to ##-###
            let zip = data.zip_code
            zip = `${zip.substring(0, 2)}-${zip.substring(2, 5)}`
            setValue('zipCode', zip)
        } else {
            setValue('zipCode', data.zip_code)
        }
    }
    if (data.street) setValue('street', data.street)
    if (data.house_number) setValue('houseNumber', data.house_number)
}
const fetchCompanyInfo = async () => {
    if (
        isValidCompanyId(businessId.value) === true &&
        (isSlovakCompany.value || isPolishCompany.value || isCroatianCompany.value)
    ) {
        dispatch('account/getCompanyInfo', {
            business_id: businessId.value,
            country_iso3: props.formData.country_iso3
        })
            .then((result) => {
                setData(result.data)
            })
            .catch((error) => {
                showMessageArray(error, 'warning')
            })
    }
}
const setError = (key, value) => {
    state.errors[key] = value
}
const checkCompany = async () => {
    try {
        if (businessId.value) {
            // remove all error messages related to businessID check - revalidate
            state.errors['business_id'] = null

            await apiRequest(
                `verify/business-id?business_id=${businessId.value}&country_iso3=${props.formData.country_iso3}`
            )
            await fetchCompanyInfo()
        }
    } catch (e) {
        if (e.errors) {
            let errors = e.errors
            for (const [key, value] of Object.entries(errors)) {
                switch (key) {
                    case 'business_id':
                        setError('business_id', value[0])
                        break
                    default:
                        showMessage(value[0])
                        break
                }
            }
            return
        }
        showMessage(e.message)
    }
}
const validateVatId = async () => {
    if (state.canValidateVat && getters['account/isEUMemberState']) {
        try {
            commit('setLoader', 1, { root: true })
            await apiRequest.post('client/validate-vat', { vat_id: vatId.value })
        } catch (error) {
            if (error.response?.status !== 503) {
                if (error.response?.status === 419 && state.isVatPayer) {
                    showMessageArray(error.response.data.errors)
                } else {
                    showMessageArray(error.errors)
                }
            }
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }
}

onMounted(() => {
    store.subscribeAction({
        after: (action) => {
            if (action.type === 'account/getAccount') {
                state.currentBusinessId = store.state.account.businessId
            }
        }
    })
})
</script>

<template>
    <div class="company-form">
        <v-row no-gutters>
            <v-col class="pa-0 mb-2" cols="12" md="4">
                <CountrySelect
                    :country="formData.country_iso3"
                    @setValue="setValue('country_iso3', $event)"
                    @update="checkCompany"
                />
            </v-col>
            <v-col class="pa-0 pl-md-8 mb-2" cols="12" md="4">
                <v-text-field
                    ref="business_id"
                    v-model="businessId"
                    :loading="isLoading"
                    :error-messages="state.errors.business_id"
                    :label="t(`Client.${companyIdPlaceholder}`) + '*'"
                    :rules="[isNotEmpty, isValidCompanyId]"
                    data-testid="ico"
                    variant="outlined"
                    @focusout="checkCompany"
                    @update:modelValue="state.errors.business_id = ''"
                />
            </v-col>
            <v-col class="pa-0 pl-md-8 mb-2" cols="12" md="4">
                <v-text-field
                    v-model="companyName"
                    :label="t('Client.CompanyName') + '*'"
                    :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(200)]"
                    data-testid="companyName"
                    variant="outlined"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="pa-0 mb-2" cols="12" md="4">
                <v-checkbox
                    v-model="isSelfEmployed"
                    :label="t('Client.IAmSelfEmployedPerson')"
                    data-testid="selfEmployedCheckbox"
                />
                <v-text-field
                    v-if="!isPolishCompany"
                    v-model="taxId"
                    :label="t(`Client.${companyTaxIdPlaceholder}`)"
                    :rules="[isValidTax]"
                    data-testid="taxId"
                    variant="outlined"
                />
            </v-col>
            <v-col class="pa-0 pl-md-8 mb-2" cols="12" md="4">
                <v-checkbox
                    v-model="state.isVatPayer"
                    :label="t('Client.IAmVatPayer')"
                    class="align-center"
                    data-testid="vatPayerCheckbox"
                />
                <v-text-field
                    v-if="state.isVatPayer"
                    v-model="vatId"
                    :label="t('Client.VatId') + '*'"
                    :loading="isLoading"
                    :rules="[isNotEmpty, isValidVat, maxCharsExceeded(14)]"
                    data-testid="vatId"
                    variant="outlined"
                    @focusout="validateVatId()"
                />
            </v-col>
        </v-row>
    </div>
</template>
