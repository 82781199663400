<script setup>
import { ref, computed, onMounted, reactive } from 'vue'
import store from '@/store'

// utils
import formatDecimal from '@/utils/formatDecimal'
import markdownParser from '@/utils/markdownParser'

// configs
import { Lang } from '@/configs'

// components
import MeterWithInfo from '@/pages/drivers/components/helpers/MeterWithInfo.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const emit = defineEmits(['setTariff'])
const props = defineProps({
    tariffId: {
        type: Number,
        default: null
    },
    isAddDriver: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { getters, dispatch } = store

const tariffs = ref([])
const activeTariffId = ref(!props.isAddDriver ? store.state.drivers.driver.activeTariffId : null)

const state = reactive({
    breakPoint: 1600
})

const getCurrency = computed(() => {
    return (
        import.meta.env[`VITE_APP_I18N_CURRENCY_${locale.value.toUpperCase()}`] ||
        import.meta.env.VITE_APP_I18N_CURRENCY
    )
})

const locale = computed(() => {
    return getters['account/locale']
})

const selectedTariff = computed(() => {
    if (props.tariffId) {
        return tariffs.value.find((tariff) => tariff.id === props.tariffId)
    }
    return null
})

const setTariff = (data) => {
    state.key = state.key + 1
    emit('setTariff', {
        id: data.id,
        price: data.monthlyPrice || null
    })
}

onMounted(async () => {
    if (!getters['tariffs/tariffs'].length) await dispatch('tariffs/getTariffs')
    tariffs.value = getters['tariffs/tariffs']
    if (!props.tariffId) {
        setTariff({ id: tariffs.value[0].id, monthlyPrice: tariffs.value[0].monthlyPrice })
    }
})
</script>

<template>
    <div v-if="selectedTariff" class="tariffs">
        <v-slide-group v-model="selectedTariff" :show-arrows="false" mobile-breakpoint="sm" class="mb-6">
            <v-slide-group-item v-for="tariff in tariffs" v-slot="{ isSelected, toggle }" :key="tariff" :value="tariff">
                <v-card
                    :color="tariff.id === selectedTariff.id ? 'primary' : 'white'"
                    elevation="0"
                    width="320px"
                    class="tariff ma-2"
                    :data-testid="`priceProgram-${tariff.name}`"
                    :class="{
                        selected: tariff.id === selectedTariff.id,
                        grayscale: tariff.id === activeTariffId,
                        'new-driver': props.isAddDriver
                    }"
                >
                    <template v-if="tariff.priceList.extraKeys.ribbon">
                        <div class="ribbon text-caption text-center white--text pa-1">
                            {{ tariff.priceList.extraKeys.ribbon }}
                        </div>
                    </template>
                    <h2 class="title text-h6 text-uppercase text-center px-4 py-6">
                        {{ tariff.name }}
                    </h2>
                    <template v-if="tariff.priceList.freeKwh !== null">
                        <h3 class="free-kwhs text-subtitle-1 text-center">
                            {{ t('registrationWizard.freeKWhs') }}
                        </h3>
                        <h2 class="text-h4 text-center font-weight-light mb-4">{{ tariff.priceList.freeKwh }} kWh</h2>
                    </template>
                    <v-divider class="pb-6" :dark="tariff.id === selectedTariff.id" />
                    <h3 class="text-subtitle-1 text-center mb-2">
                        {{ t('registrationWizard.charging') }}
                    </h3>
                    <v-row no-gutters class="metters justify-center py-8">
                        <v-col class="d-flex flex-column justify-start align-center">
                            <MeterWithInfo
                                class="box"
                                :price-list="tariff.priceList.AC[0]"
                                :currency="getCurrency"
                                :show-power="tariff.priceList.DC.length > 1"
                            />
                        </v-col>
                        <v-col class="d-flex flex-column justify-start align-center">
                            <MeterWithInfo
                                class="box"
                                :price-list="tariff.priceList.DC[0]"
                                :currency="getCurrency"
                                :show-power="tariff.priceList.DC.length > 1"
                            />
                        </v-col>
                        <v-col
                            v-if="tariff.priceList.DC.length > 1"
                            class="d-flex flex-column justify-start align-center"
                        >
                            <MeterWithInfo
                                class="box"
                                :price-list="tariff.priceList.DC[1]"
                                :currency="getCurrency"
                                :show-power="tariff.priceList.DC.length > 1"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pa-5">
                        <v-col @click="toggle">
                            <v-btn
                                rounded="xl"
                                large
                                :disabled="tariff.id === activeTariffId"
                                class="next-button text-none"
                                :color="
                                    tariff.id === selectedTariff.id
                                        ? 'rgb(var(--v-theme-white))'
                                        : 'rgb(var(--v-theme-primary))'
                                "
                                :data-testid="`buyProgram-${tariff.name}`"
                                @click="setTariff(tariff)"
                            >
                                <template v-if="tariff.id === activeTariffId">
                                    {{ t('Pricing.CurrentlyActive') }}
                                </template>
                                <template v-else>
                                    <template v-if="locale !== Lang.pl">
                                        <template v-if="tariff.priceList.priceOff">
                                            <div
                                                v-html="
                                                    markdownParser(
                                                        tariff.priceList.priceOff.buttonTitle[store.state.account.lang]
                                                    )
                                                "
                                            />
                                        </template>
                                        <template v-else>
                                            {{
                                                t('registrationWizard.selectForMonthlyPrice', {
                                                    price: tariff.priceList.monthlyPrice
                                                        ? formatDecimal(tariff.priceList.monthlyPrice)
                                                        : 0,
                                                    currency: getCurrency
                                                })
                                            }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="tariff.priceList.priceOff">
                                            <div
                                                v-html="
                                                    markdownParser(
                                                        tariff.priceList.priceOff.buttonTitle[store.state.account.lang]
                                                    )
                                                "
                                            />
                                        </template>
                                        <template v-else>
                                            {{
                                                t('registrationWizard.selectForMonthlyPrice', {
                                                    price: tariff.priceList.monthlyPrice
                                                        ? formatDecimal(tariff.priceList.monthlyPrice)
                                                        : 0,
                                                    currency: getCurrency
                                                })
                                            }}
                                        </template>
                                    </template>
                                </template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <template v-if="tariff.priceList.priceOff && tariff.priceList.priceOff.tariffDescription">
                        <v-divider :dark="isSelected" />
                        <v-row no-gutters class="priceOffDescription pa-4">
                            <v-col>
                                <div v-html="markdownParser(t(`${tariff.priceList.priceOff.tariffDescription}`))" />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-if="tariff.priceList.extraKeys">
                        <v-divider :dark="isSelected" />
                        <v-row no-gutters class="pa-4 text-center">
                            <v-col>
                                <h4 class="text-body-1 font-weight-medium" data-testid="roamingTitle">
                                    {{ tariff.priceList.extraKeys.title }}
                                </h4>
                                <h5 class="mb-2">{{ tariff.priceList.extraKeys.subtitle }}</h5>
                                <p class="text-caption mb-2" data-testid="roamingText">
                                    {{ tariff.priceList.extraKeys.content }}
                                </p>
                                <p class="text-caption">{{ tariff.priceList.extraKeys.mainBenefits }}</p>
                            </v-col>
                        </v-row>
                    </template>
                </v-card>
            </v-slide-group-item>
        </v-slide-group>
        <div class="info-panel text-blue bg-light-blue-lighten-4 mx-auto mb-4 pa-4">
            <div class="information mb-4">
                <div class="d-flex align-center mb-2">
                    <v-icon class="text-blue align-start"> mdi-information-outline </v-icon>
                    <h4 class="text-subtitle-2 ml-1">
                        {{ t('registrationWizard.overstayFeeTitle') }}
                    </h4>
                </div>
                <div class="text-caption ma-0 pl-3" v-html="markdownParser(t(`priceProgram.overstayFeeContent`))"></div>
            </div>
        </div>
    </div>
</template>
