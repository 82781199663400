import qs from 'qs'

// Cookies
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

// Utils
import { apiRequest } from '@/utils/apiRequest'
import { mapPagination } from '@/utils/mapPagination'
import isEmptyObj from '@/utils/isEmptyObj'

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({ dispatch }, error) => {
        if (typeof error.errors !== 'undefined' && error.errors.length > 0) {
            throw error.errors
        } else if (
            error.response &&
            typeof error.response.data !== 'undefined' &&
            typeof error.response.data.errors !== 'undefined'
        ) {
            throw error.response.data.errors
        }

        if (typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if (typeof error.message !== 'undefined') {
                throw error.message
            } else if (typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if (data) {
            await apiRequest.post('auth/logout', data).catch((error) => {
                dispatch('checkStatus', error)
            })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    getChargers: async ({ commit, dispatch }, options) => {
        commit('setLoader', 1, { root: true })
        const { data, headers } = await apiRequest('facility/charger-management/chargers-with-filters', {
            params: options,
            paramsSerializer: (params) => qs.stringify(params, { skipNulls: true })
        })
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })

        const pagination = await mapPagination(headers)
        commit('setChargers', data.chargers)
        commit('setPagination', pagination)
        if (isEmptyObj(options.filters)) {
            dispatch('getFilters')
            return
        }
        commit('setFilters', data.filters)
    },
    getCharger: async ({ commit, dispatch }, id) => {
        commit('setLoader', 1, { root: true })
        const { data } = await apiRequest(`facility/charger-management/chargers/${id}`)
            .catch((error) => {
                if (error.response.status === 404) {
                    window.location.href = `${window.location.protocol}//${window.location.host}/404`
                }
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
        commit('setCharger', data)
    },
    getFilters: async ({ commit, dispatch }, options) => {
        commit('setLoader', 1, { root: true })
        const { data } = await apiRequest('facility/charger-management/chargers/filters', {
            params: options,
            paramsSerializer: (params) => qs.stringify(params, { skipNulls: true })
        })
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
        commit('setFilters', data)
    },
    restartCharger: async ({ commit, dispatch }, { id }) => {
        commit('setLoader', 1, { root: true })
        return await apiRequest
            .get(`facility/charger-management/chargers/restart/${id}`)
            .then(({ data }) => {
                return data
            })
            .catch((error) => {
                console.log(error)
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
    }
}
