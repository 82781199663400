<script setup>
import store from '@/store'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { computed, onMounted, reactive, ref, onBeforeMount } from 'vue'

// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

const { dispatch, getters, commit } = store

// configs
import { Lang } from '@/configs/index.js'

// components
import LoginRegistration from '@/components/LoginRegistration.vue'
import Btn from '@/components/inputs/Btn.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'

// utils
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessage'
import showMessageArray from '@/utils/errorMessageArray'

// validations
import useValidations from '@/composables/useValidations'

const { isNotEmpty, isLongPassword } = useValidations()

const { t } = useI18n()
const route = useRoute()
const password = ref('')
const form = ref('')

const state = reactive({
    recaptchaToken: null,
    isFormValid: false,
    email: '',
    password: '',
    isPasswordVisible: false,
    isNotEmpty,
    isLongPassword,
    errors: {
        email: '',
        password: ''
    }
})

const isRememberChecked = computed({
    get() {
        return store.state.account.remember_me
    },
    set(value) {
        commit('account/setRememberMe', value)
    }
})

const siteKey = computed(() => {
    return store.state.settings.recaptchaSiteKey
})

const checkRecaptchaToken = computed(() => {
    if (siteKey.value === null) {
        return false
    }
    return !state.recaptchaToken
})

const locale = computed(() => {
    // TODO - Andrej - This needs to be refactored
    const localeFallback = window.location.origin.indexOf('.sk') > -1 ? Lang.sk.toUpperCase() : Lang.pl.toUpperCase()
    return store.state.account.locale || import.meta.env.VITE_APP_I18N_LOCALE || localeFallback
})

const language = () => {
    return store.state.account.lang || import.meta.env.VITE_APP_I18N_LOCALE || Lang.en
}

const link = () => {
    return import.meta.env[`VITE_APP_ONE_TIME_CHARGING_LINK_${locale.value.toUpperCase()}`] + `/${language}`
}

const auth = () => {
    dispatch('account/auth', {
        email: state.email,
        password: state.password,
        'g-recaptcha-response': state.recaptchaToken,
        remember_me: isRememberChecked.value
    }).then(() => {
        redirectFarther()
    }).catch(error => {
        if (error['g-recaptcha-response']) {
            error['g-recaptcha-response'].forEach(message => {
                showMessage(message)
            })
        } else {
			if(typeof error.error === 'string') {
				showMessage(error.error)
			} else {
				for (const [key, value] of Object.entries(error)) {
					switch (key) {
					case 'email':
						setError('email', value[0])
						break
					case 'password':
						setError('password', value[0])
						break
					default:
						showMessage(value[0])
						break
					}
				}
			}
        }
        state.recaptchaToken = null
    }).finally(() => {
        state.recaptchaToken = null
    })
}

const login = async () => {
    const isFormValid = form.value
    if (!isFormValid?.isValid) {
        return
    }

    if (siteKey.value) {
        grecaptcha.enterprise.ready(async () => {
            state.recaptchaToken = await grecaptcha.enterprise.execute(siteKey.value, { action: 'login' })
            auth()
        })
    } else {
        auth()
    }
}

const redirectFarther = () => {
    let link = !store.state.account.actionAfterLogin ? (cookies.get('redirect_after_login') ?? 'news') : 'registration-wizard'

    if (cookies.isKey('redirect_after_login')) {
        cookies.remove('redirect_after_login')
    }

    if (getters['oauth/isOAuth']) {
        link = 'oauth'
    }

    window.location.href = link
}

const setError = (key, value) => {
    state.errors[key] = value
}

const resetFormValidation = () => {
    if (state.errors.email.trim().length) {
        state.errors.email = ''
    }
    if (state.errors.password.trim().length) {
        state.errors.password = ''
    }
}

const verifyEmail = async () => {
    const url = new URL(window.location)
    const emailToken = route.query.emailtoken
    try {
        await apiRequest.post('email/verify', { code: emailToken })
        showMessage(t('logIn.EmailVerified'), 'success')
    } catch (error) {
        showMessageArray(error.errors.code)
    } finally {
        url.searchParams.delete('emailtoken')
        window.history.pushState({}, '', url)
    }
}
onMounted(async () => {
    // recaptcha
    const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
    if (recaptchaSiteKey) {
        commit('settings/setRecaptchaSiteKey', { recaptchaSiteKey })
    }

    if (route.query.client_id && route.query.redirect_uri) {
        const oauthClient = {
            client_id: route.query.client_id ?? '',
            redirect_uri: route.query.redirect_uri ?? '',
            response_type: route.query.response_type ?? 'code',
            scope: null,
            response_mode: route.query.response_mode ?? 'form_post',
            state: route.query.state ?? ''
        }
        //save to local storage
        await dispatch('oauth/saveClient', oauthClient)
    }

    if (route.query.emailtoken) {
        await verifyEmail()
    }

    if (getters['account/isAuth']) {
        redirectFarther()
    }
})
</script>

<template>
    <div class="login-wrapper">
        <LoginRegistration>
            <template v-slot:header>
                <LangSwitcher />
                <Btn
                    :href="link()"
                    variant="text"
                >
                    {{ t('logIn.OneTimeCharging') }}
                </Btn>
            </template>
            <template v-slot:main>
                <v-form
                    ref="form"
                    v-model="state.isFormValid"
                    class="form"
                    @submit.prevent="login"
                >
                    <h1 class="form__title font-weight-thin text-center mb-4">
                        {{ t('logIn.LogIn') }}
                    </h1>
                    <p class="mb-8 text-center">
                        {{ t('logIn.Don’tHaveAnAccount?') }}
                        <router-link
                            class="registration-link"
                            to="registration"
                        >
                            {{ t('logIn.SignUp') }}
                        </router-link>
                    </p>
                    <div class="d-flex flex-column">
                        <label class="form__label">
                            <small>{{ t('logIn.Email') }}</small>
                        </label>
                        <v-text-field
	                        name="username"
                            v-model="state.email"
                            :error-messages="state.errors.email"
                            :rules="[isNotEmpty]"
                            data-testid="loginEmail"
                            hide-details
                            type="email"
                            variant="outlined"
                            @update:model-value="resetFormValidation"
                        />
                    </div>
                    <div class="d-flex flex-column">
                        <label class="form__label">
                            <small>{{ t('logIn.Password') }}</small>
                        </label>
                        <v-text-field
	                        name="password"
                            ref="password"
                            v-model="state.password"
                            :error-messages="state.errors?.password"
                            :rules="[isNotEmpty, isLongPassword]"
                            :type="state.isPasswordVisible ? 'text' : 'password'"
                            data-testid="loginPassword"
                            height="35px"
                            variant="outlined"
                            @update:model-value="resetFormValidation"
                        >
                            <template v-slot:append-inner>
                                <v-icon
                                    :icon="state.isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click="state.isPasswordVisible = !state.isPasswordVisible"
                                />
                            </template>
                        </v-text-field>
                    </div>
                    <div class="form__footer">
                        <v-checkbox
                            v-model="isRememberChecked"
                            :label="t('logIn.RememberMe')"
                            hide-details
                        />
                        <router-link
                            class="text-gray"
                            to="reset-password"
                        >
                            {{ t('logIn.ForgotPassword?') }}
                        </router-link>
                    </div>
                    <Btn
                        :disabled="!state.isFormValid"
                        :loading="store.getters['isLoading']"
                        class="buttons__btn form__submit w-100"
                        data-testid="loginSubmit"
                        large
                        type="submit"
                    >
                        {{ t('logIn.LogIn') }}
                    </Btn>
                </v-form>
            </template>
        </LoginRegistration>
    </div>
</template>