<script setup>
import router from '@/router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { t } = useI18n()
</script>

<template>
    <v-tooltip
        location="bottom"
        class="payer-info pa-2"
        :open-on-click="true"
        :open-on-hover="false"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ props }">
            <v-btn icon="mdi-information-outline" variant="plain" v-bind="props" />
        </template>
        <div class="payer-info-container bg-grey-lighten-2">
            <h3 class="text-caption">{{ t('driverDetails.payerInfoTitle') }}</h3>
            <br />
            <h3 class="text-caption font-weight-bold">{{ t('driverDetails.payerInfoAccOwner') }}:</h3>
            <h4 v-if="store.state.account.companyName" class="text-caption">
                {{ store.state.account.companyName }}
            </h4>
            <h4 class="text-caption">
                {{ `${store.state.account.firstName} ${store.state.account.lastName}` }}
            </h4>
            <h4 class="text-caption">{{ store.state.account.email }}</h4>
            <h4 class="text-caption">{{ store.state.account.phone }}</h4>
            <br />
            <v-btn variant="text" class="text-none text-blue" @click="router.push('/logout')">{{
                t('driverDetails.payerInfoButton')
            }}</v-btn>
        </div>
    </v-tooltip>
</template>
