import router from '@/router'

import Usercentrics, { UI_LAYER, UI_VARIANT } from '@usercentrics/cmp-browser-sdk'

// Zoho
import {initializeZoho} from '@/modules/zoho'

// Usercentrics
// https://admin.usercentrics.eu/#/v2/configuration/setup
// https://docs.usercentrics.com/cmp_browser_sdk/4.20.1/index.html
const initUserCentrics = (app) => {
    const UC = new Usercentrics(import.meta.env.VITE_APP_COOKIE_BOT_ID)
    UC.init().then((initialUIValues) => {
        const categories = UC.getCategoriesBaseInfo()

        // Google Analytics Integration
        const googleAnalyticsService = categories.find(el => el.slug === 'functional').services.find(el => el.id === import.meta.env.VITE_APP_UC_GOOGLE_ANALYTICS) || null
        if (import.meta.env.VITE_APP_GTAG && googleAnalyticsService && googleAnalyticsService.consent.status) {
            Vue.use(VueGtag, {
                config: { id: import.meta.env.VITE_APP_GTAG }
            })
        }
    })
}

export default initUserCentrics