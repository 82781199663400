import {Lang} from '@/configs/index.js'

/* eslint-disable */
function loadScript(src, defer) {
    let script = document.createElement('script');
    script.src = src;
    script.rel = "preload";
    script.async = false;
    script.defer = defer
    script.id = "zsiqscript"
    document.body.append(script);
}

export const initializeZoho = (zohoService, zohoSalesService, store) => {
    let codes = {
        PL: {
            PL: '1805a74fc9c5f3e30015c8fac715c337d325713b15b2d753aa0e8f46b0254c30',
            EN: 'c9017fbe87438579ab97bed5a47a28e35485763cc015057705b1a993dce0513b09412f876ff31651092bc1a9a789c0ac',
        },
        SK: {
            SK: '4b1625a868f392edcc156cf379b873606039469215c3156d5c7fd8aed1bf40eecf67f86874955d91eee2b23587b384d1',
            EN: 'be2cf47ae6c0efe80fec2b79c7cc319addfab9b0146a6822a5c201528d02a0e80b5e354ee404cab7a41e04eb58edaf7d',
        },
        HR: {
            HR: 'siq0e2da94e047899914bbfbf87ab39263349063e694e850a7d63a71b9e3d983f7e',
            EN: 'siq0e2da94e047899914bbfbf87ab3926334b68f2ea3db2046a30c95a688b2e8145',
        },
    }
    if (store) {
        const localeFallback = window.location.origin.indexOf('.sk') > -1 ? Lang.sk.toUpperCase() : Lang.pl.toUpperCase()
        const locale = typeof store.state.account !== 'undefined' && store.state.account.locale?.toUpperCase() || import.meta.env.VITE_APP_I18N_LOCALE?.toUpperCase() || localeFallback
        const language = typeof store.state.account !== 'undefined' && store.state.account.lang?.toUpperCase() || import.meta.env.VITE_APP_I18N_LOCALE?.toUpperCase()  || Lang.en.toUpperCase()
        const widgetcode = codes[locale][language]

        // Zoho
        if(zohoService && zohoService.consent.status) {
            window.$zoho = window.$zoho || {};
            loadScript("https://salesiq.zoho.eu/widget", true)
            let zsiqwidget = document.createElement('div')
            zsiqwidget.id = "zsiqwidget"
            document.body.append(zsiqwidget)
        }

        // Zoho - sales
        if(zohoSalesService && zohoSalesService.consent.status) {
            window.$zoho.salesiq = window.$zoho.salesiq || {
                widgetcode: widgetcode,
                values: {}
            }
            window.$zoho.salesiq.ready = function () {
                let texts = store.state.translation[store.state.account.lang]
                if (store.state.account.email) {
                    window.$zoho.salesiq.visitor.name(store.state.account.firstName + ' ' + store.state.account.lastName);
                    window.$zoho.salesiq.visitor.email(store.state.account.email);
                    window.$zoho.salesiq.visitor.contactnumber(store.state.account.phone);
                }
                ///$zoho.salesiq.visitor.info(@json($user));
                window.$zoho.salesiq.language(store.state.account.lang);
                window.$zoho.salesiq.chatbutton.texts([
                    [texts.chatbuttonOnlineMessage, texts.chatbuttonOnline],
                    [texts.chatbuttonOfflineMessage, texts.chatbuttonOffline]
                ]);
                window.$zoho.salesiq.chat.systemmessages({
                    "waiting": texts.waiting,
                    "offlinecomplete": texts.offlinecomplete,
                    "busy": texts.busy,
                    "busycomplete": texts.busycomplete,
                    "engaged": texts.engaged,
                    "engagedcomplete": texts.engagedcomplete,
                    "ratingtitle": texts.ratingtitle,
                    "ratingcomplete": texts.ratingcomplete
                });
                if (document.URL.includes("#zsiqopen")) {
                    window.$zoho.salesiq.floatwindow.visible("show");
                } else {
                    window.$zoho.salesiq.floatwindow.visible("hide");
                }
            }
        }

    }
}
