<script setup>
import store from '@/store'
import { reactive, computed, onMounted } from 'vue'

// configs
import { Lang } from '@/configs/index.js'

// components
import PricePrograms from '@/components/PricePrograms.vue'
import InfoMessage from '@/components/InfoMessage.vue'

// utils
import formatDecimal from '@/utils/formatDecimal'
import markdownParser from '@/utils/markdownParser'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { getters } = store

const emit = defineEmits(['setTariff'])
const props = defineProps({
    isHideDetails: {
        type: Boolean,
        required: false,
        default: false
    },
    isAddDriver: {
        type: Boolean,
        required: false,
        default: false
    },
    tariffId: {
        type: [Number, Object],
        required: true,
        default: null
    }
})

const state = reactive({
    mobil: ''
})

const locale = computed(() => getters['account/locale'].toUpperCase())
const tariffs = computed(() => getters['tariffs/tariffs'])
const getCurrency = computed(() => {
    return store.state.account?.locale.toUpperCase() === Lang.sk.toUpperCase()
        ? import.meta.env.VITE_APP_I18N_CURRENCY_SK
        : import.meta.env.VITE_APP_I18N_CURRENCY_PL
})

const setTariff = (data) => {
    emit('setTariff', data)
}
const setWindowWidth = () => {
    state.mobil = window.innerWidth < 720 ? '_mobil' : ''
}

onMounted(() => {
    setWindowWidth()
})
</script>

<template>
    <div
        class="pricing"
        :class="{
            pricing__padding: $route.name === 'pricePrograms',
            'selection-controls': $route.name === 'pricePrograms'
        }"
    >
        <div class="pricing__move-car">
            <h2 class="text-center pricing__title pt-9">
                {{ t('addDriver.CheckOutPlanForYou') }}
            </h2>
        </div>
        <v-container>
            <PricePrograms :is-add-driver="isAddDriver" :tariff-id="tariffId" @setTariff="setTariff" />
        </v-container>
        <div class="mt-2 mb-2 mx-auto">
            <InfoMessage>
                <div class="text-caption" v-html="t('Pricing.ChangePriceProgramNote')" />
            </InfoMessage>
        </div>
    </div>
</template>
