const PaymentProviders = Object.freeze({
    Braintree: 'braintree',
    Payout: 'payout',
    BankTransfer: 'BankTransfer',
    Mock: 'mock',
    canPay: (status) => {
        return PaymentProviders.Braintree === status ||
            PaymentProviders.Payout === status ||
            PaymentProviders.Mock === status
            ? true
            : false
    }
})

export { PaymentProviders }
