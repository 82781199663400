<script setup>
import { useStore } from 'vuex'
import { computed, onMounted, reactive } from 'vue'

// components
import CircleGraph from '@/pages/dashboard/components/CircleGraph.vue'
import BarGraph from '@/pages/dashboard/components/BarGraph.vue'
import HorizontalBarGraph from '@/pages/dashboard/components/HorizontalBarGraph.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// utils
import setPageTitle from '@/utils/pageTitle'

// use width
import useWindowWidth from '@/composables/windowWidth.js'
const { windowWidth } = useWindowWidth()

const store = useStore()
const { getters } = store

const state = reactive({
    roamingSessionsPercentEnabled: false
})

const isLoading = computed(() => {
    return getters['isLoading']
})
const maxChargingSessions = computed(() => {
    // return [
    //     {
    //         driverID: 17562,
    //         driverName: 'S&M Gregor Šiškovič',
    //         total: 9,
    //         totalAc: 1,
    //         totalDc: 8
    //     },
    //     {
    //         driverID: 58509,
    //         driverName: 'S&M Jozef Skoviera',
    //         total: 7,
    //         totalAc: 1,
    //         totalDc: 6
    //     },
    //     {
    //         driverID: 48069,
    //         driverName: 'S&M Marek Vidovič',
    //         total: 5,
    //         totalAc: 4,
    //         totalDc: 1
    //     },
    //     {
    //         driverID: 85027,
    //         driverName: 'S&M Richard Ujhelyi',
    //         total: 4,
    //         totalAc: 4,
    //         totalDc: 0
    //     },
    //     {
    //         driverID: 146421,
    //         driverName: 'S&M Lukáš Marcinek',
    //         total: 2,
    //         totalAc: 2,
    //         totalDc: 0
    //     },
    //     {
    //         driverID: 75493,
    //         driverName: 'S&M Robert Kacenak',
    //         total: 1,
    //         totalAc: 1,
    //         totalDc: 0
    //     }
    // ]
    return store.state.fleetDashboard.data.maxChargingSessions
})
const maxEnergy = computed(() => {
    // return [
    //     {
    //         driverID: 17562,
    //         driverName: 'S&M Gregor Šiškovič',
    //         total: '241.75',
    //         totalAc: '15.69',
    //         totalDc: '226.06'
    //     },
    //     {
    //         driverID: 58509,
    //         driverName: 'S&M Jozef Skoviera',
    //         total: '154.44',
    //         totalAc: '0.00',
    //         totalDc: '154.44'
    //     },
    //     {
    //         driverID: 48069,
    //         driverName: 'S&M Marek Vidovič',
    //         total: '133.67',
    //         totalAc: '95.56',
    //         totalDc: '38.11'
    //     },
    //     {
    //         driverID: 85027,
    //         driverName: 'S&M Richard Ujhelyi',
    //         total: '116.57',
    //         totalAc: '116.57',
    //         totalDc: null
    //     },
    //     {
    //         driverID: 75493,
    //         driverName: 'S&M Robert Kacenak',
    //         total: '27.66',
    //         totalAc: '27.66',
    //         totalDc: null
    //     },
    //     {
    //         driverID: 146421,
    //         driverName: 'S&M Lukáš Marcinek',
    //         total: '14.74',
    //         totalAc: '14.74',
    //         totalDc: null
    //     }
    // ]
    return store.state.fleetDashboard.data.maxEnergy
})
const maxTime = computed(() => {
    // return [
    //     {
    //         driverID: 17562,
    //         driverName: 'S&M Gregor Šiškovič',
    //         total: '241.75',
    //         totalAc: '15.69',
    //         totalDc: '226.06'
    //     },
    //     {
    //         driverID: 58509,
    //         driverName: 'S&M Jozef Skoviera',
    //         total: '154.44',
    //         totalAc: '0.00',
    //         totalDc: '154.44'
    //     },
    //     {
    //         driverID: 48069,
    //         driverName: 'S&M Marek Vidovič',
    //         total: '133.67',
    //         totalAc: '95.56',
    //         totalDc: '38.11'
    //     },
    //     {
    //         driverID: 85027,
    //         driverName: 'S&M Richard Ujhelyi',
    //         total: '116.57',
    //         totalAc: '116.57',
    //         totalDc: null
    //     },
    //     {
    //         driverID: 75493,
    //         driverName: 'S&M Robert Kacenak',
    //         total: '27.66',
    //         totalAc: '27.66',
    //         totalDc: null
    //     },
    //     {
    //         driverID: 146421,
    //         driverName: 'S&M Lukáš Marcinek',
    //         total: '14.74',
    //         totalAc: '14.74',
    //         totalDc: null
    //     }
    // ]
    return store.state.fleetDashboard.data.maxTime
})
const roamingSessions = computed(() => {
    return store.state.fleetDashboard.data.roamingSessions
})
const sessionsCount = computed(() => {
    return roamingSessions.value ? roamingSessions.value.sessionCount : 0
})
const roamingSessionsCount = computed(() => {
    if (roamingSessions.value) {
        return roamingSessions.value.roamingSessionCount ? roamingSessions.value.roamingSessionCount : 0
    }
    return 0
})
const roamingSessionsPercent = computed(() => {
    if (roamingSessionsCount.value) {
        return (roamingSessionsCount.value / sessionsCount.value) * 100
    }
    return 0
})
const chargingSessions = computed(() => {
    // return [
    //     {
    //         date: '2024-06-25',
    //         ac: 3,
    //         dc: 1,
    //         all: 4
    //     },
    //     {
    //         date: '2024-06-24',
    //         ac: 4,
    //         dc: 6,
    //         all: 10
    //     },
    //     {
    //         date: '2024-06-23',
    //         ac: 1,
    //         dc: 2,
    //         all: 3
    //     },
    //     {
    //         date: '2024-06-22',
    //         ac: 0,
    //         dc: 2,
    //         all: 2
    //     },
    //     {
    //         date: '2024-06-21',
    //         ac: 3,
    //         dc: 1,
    //         all: 4
    //     },
    //     {
    //         date: '2024-06-20',
    //         ac: 1,
    //         dc: 3,
    //         all: 4
    //     },
    //     {
    //         date: '2024-06-19',
    //         ac: 2,
    //         dc: 6,
    //         all: 8
    //     },
    //     {
    //         date: '2024-06-18',
    //         ac: 2,
    //         dc: 3,
    //         all: 5
    //     },
    //     {
    //         sumAll: 40,
    //         sumAc: 16,
    //         sumDc: 24
    //     }
    // ]
    return store.state.fleetDashboard.data.chargingSessions
})
const energy = computed(() => {
    // return [
    //     {
    //         date: '2024-06-25',
    //         ac: '76.43',
    //         dc: '45.00',
    //         all: 121.43
    //     },
    //     {
    //         date: '2024-06-24',
    //         ac: '102.14',
    //         dc: '189.96',
    //         all: 292.1
    //     },
    //     {
    //         date: '2024-06-23',
    //         ac: '20.30',
    //         dc: '25.66',
    //         all: 45.96
    //     },
    //     {
    //         date: '2024-06-22',
    //         ac: 0,
    //         dc: '22.00',
    //         all: 22
    //     },
    //     {
    //         date: '2024-06-21',
    //         ac: '64.40',
    //         dc: '34.85',
    //         all: 99.25
    //     },
    //     {
    //         date: '2024-06-20',
    //         ac: '6.95',
    //         dc: '101.14',
    //         all: 108.09
    //     },
    //     {
    //         date: '2024-06-19',
    //         ac: '56.78',
    //         dc: '210.11',
    //         all: 266.89
    //     },
    //     {
    //         date: '2024-06-18',
    //         ac: '47.93',
    //         dc: '150.39',
    //         all: 198.32
    //     },
    //     {
    //         sumAll: 1154.04,
    //         sumAc: 374.93,
    //         sumDc: 779.11
    //     }
    // ]
    return store.state.fleetDashboard.data.energy
})
const chargingTime = computed(() => {
    // return [
    //     {
    //         date: '2024-06-25',
    //         ac: '969.03',
    //         dc: '45.18',
    //         all: 1014.2099999999999
    //     },
    //     {
    //         date: '2024-06-24',
    //         ac: '1217.32',
    //         dc: '182.09',
    //         all: 1399.4099999999999
    //     },
    //     {
    //         date: '2024-06-23',
    //         ac: '114.43',
    //         dc: '39.37',
    //         all: 153.8
    //     },
    //     {
    //         date: '2024-06-22',
    //         ac: 0,
    //         dc: '28.93',
    //         all: 28.93
    //     },
    //     {
    //         date: '2024-06-21',
    //         ac: '1059.78',
    //         dc: '23.30',
    //         all: 1083.08
    //     },
    //     {
    //         date: '2024-06-20',
    //         ac: '140.53',
    //         dc: '58.88',
    //         all: 199.41
    //     },
    //     {
    //         date: '2024-06-19',
    //         ac: '1005.73',
    //         dc: '129.79',
    //         all: 1135.52
    //     },
    //     {
    //         date: '2024-06-18',
    //         ac: '1072.92',
    //         dc: '128.44',
    //         all: 1201.3600000000001
    //     },
    //     {
    //         sumAll: 6215.719999999999,
    //         sumAc: 5579.74,
    //         sumDc: 635.98
    //     }
    // ]
    return store.state.fleetDashboard.data.chargingTime
})
onMounted(async () => {
    setPageTitle(t('fleetDashboard.FleetDashboard'))
    await store.dispatch('fleetDashboard/getData')
})
</script>

<template>
    <div class="fleet-dashboard">
        <h1 class="fleet-dashboard__title mb-5"
            :class="windowWidth < 960 ? 'text-center pt-5' : 'pt-7 pl-5'"
        >
            {{ t('fleetDashboard.FleetDashboard') }}
        </h1>
        <v-container fluid>
            <v-row v-if="isLoading">
                <v-col md="6" sm="12" xs="12">
                    <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col md="6" sm="12" xs="12">
                    <v-card class="d-flex justify-center graph min-height-300" flat>
                        <HorizontalBarGraph
                            v-if="maxChargingSessions"
                            :data="maxChargingSessions"
                            :data-title-field="'driverName'"
                            :data-value-field="'total'"
                            :label="'Sessions'"
                            :name="t('fleetDashboard.ChargingSessionsPerDriver')"
                            :title="t('fleetDashboard.ChargingSessionsPerDriver')"
                            :sub-title="t('fleetDashboard.ChargingSessionsPerDriverSubtitle')"
                        />
                    </v-card>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-card class="d-flex justify-center graph min-height-300" flat>
                        <HorizontalBarGraph
                            v-if="maxEnergy"
                            :data="maxEnergy"
                            :data-title-field="'driverName'"
                            :data-value-field="'total'"
                            :label="'kWh'"
                            :name="t('fleetDashboard.ConsumedEnergyPerDriver')"
                            :title="t('fleetDashboard.ConsumedEnergyPerDriver')"
                            :sub-title="t('fleetDashboard.ConsumedEnergyPerDriverSubtitle')"
                        />
                    </v-card>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-card class="d-flex justify-center graph min-height-300" flat>
                        <HorizontalBarGraph
                            v-if="maxTime"
                            :data="maxTime"
                            :data-title-field="'driverName'"
                            :data-value-field="'total'"
                            :label="'Minutes'"
                            :name="t('fleetDashboard.TimeSpentChargingPerDriver')"
                            :title="t('fleetDashboard.TimeSpentChargingPerDriver')"
                            :sub-title="t('fleetDashboard.TimeSpentChargingPerDriverSubtitle')"
                        />
                    </v-card>
                </v-col>
                <v-col v-if="state.roamingSessionsPercentEnabled" md="6" sm="12" xs="12">
                    <v-card class="d-flex justify-center pa-6 min-height-300" flat>
                        <CircleGraph
                            :percent="roamingSessionsPercent"
                            :title="t('fleetDashboard.RoamingSessions')"
                            :sub-title="t('fleetDashboard.RoamingSessionsSubtitle')"
                            :value-text="String(roamingSessionsCount)"
                        />
                    </v-card>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-card class="d-flex justify-center graph min-height-300" flat>
                        <BarGraph
                            v-if="chargingSessions"
                            :ac-data-field="'ac'"
                            :data="chargingSessions"
                            :dc-data-field="'dc'"
                            :name="t('fleetDashboard.ChargingSessions')"
                            :total-data-field="'all'"
                            :title="t('fleetDashboard.ChargingSessions')"
                        />
                    </v-card>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-card class="d-flex justify-center graph min-height-300" flat>
                        <BarGraph
                            v-if="energy"
                            :ac-data-field="'ac'"
                            :data="energy"
                            :dc-data-field="'dc'"
                            :name="t('fleetDashboard.ConsumedEnergy')"
                            :title="t('fleetDashboard.ConsumedEnergy')"
                            :total-data-field="'all'"
                        />
                    </v-card>
                </v-col>
                <v-col md="6" sm="12" xs="12">
                    <v-card class="d-flex justify-center graph min-height-300" flat>
                        <BarGraph
                            v-if="chargingTime"
                            :ac-data-field="'ac'"
                            :data="chargingTime"
                            :dc-data-field="'dc'"
                            :name="t('fleetDashboard.TimeSpentCharging')"
                            :total-data-field="'all'"
                            :title="t('fleetDashboard.TimeSpentCharging')"
                        />
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
