<script setup>
import router from '@/router'
import { useStore } from 'vuex'
import { computed, onBeforeMount, onMounted, reactive } from 'vue'

// helpers
import { getDefaultState } from '@/store/modules/drivers/helper'
import useWindowWidth from '@/composables/windowWidth'

// utils
import { orderCard } from '@/utils/eshop'
import showMessage from '@/utils/errorMessage'
import { getEshopProducts } from '@/utils/eshop'

// components
import Btn from '@/components/inputs/Btn.vue'
import Modal from '@/components/modals/Modal.vue'
import Sessions from '@/pages/sessions/Sessions.vue'
import BtnGoBack from '@/components/inputs/BtnGoBack.vue'
import FreeKWh from '@/pages/drivers/components/FreeKWh.vue'
import DriverInfo from '@/pages/drivers/components/DriverInfo.vue'
import ChargingForm from '@/components/chargingForm/ChargingForm.vue'
import PriceProgram from '@/pages/drivers/components/PriceProgram.vue'
import ChargingCard from '@/pages/drivers/components/ChargingCard.vue'
import TariffChanges from '@/pages/drivers/components/TariffChanges.vue'
import VehicleDetails from '@/pages/drivers/components/VehicleDetails.vue'

// translations
import { useI18n } from 'vue-i18n'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
const { t } = useI18n()

const route = useRoute()

const store = useStore()
const { dispatch, commit, getters } = store

const { windowWidth } = useWindowWidth()

const state = reactive({
    packages: [],
    isPopupAddCard: false,
    isPopupOrderCard: false,
    showChargingForm: false,
    showDialogBox: false,
    isPayer: getters['account/roles'].find((role) => role === 'payer'),
    navigateTo: '',
    driverId: null
})

const eshopProducts = computed(() => store.state.eshop.products)
const driver = computed(() => {
    return store.state.drivers.driver
})
const isMobileView = computed(() => {
    return windowWidth < 960
})

const refetchDriver = async () => {
    await dispatch('drivers/getDriver', state.driverId)
}
const getRfidPrice = () => {
    const rfid = eshopProducts.value.find((el) => el.code.trim().toLowerCase() === 'rfid') || null
    if (rfid) {
        return rfid.price
    }
    return null
}
const requestOrderCard = async () => {
    // check if RFID card in eshop exists first
    const rfid = eshopProducts.value.find((el) => el.code.trim().toLowerCase() === 'rfid') || null
    if (rfid) {
        const driver_id = state.driverId
        const items = [
            {
                product_id: rfid.id,
                quantity: 1
            }
        ]
        await orderCard(rfid, driver_id, items)
        await getEshopProducts(state.driverId)
        state.isPopupOrderCard = false
    } else {
        showMessage(t('orderCard.unableToOrder'))
    }
}
const getFreeKWh = async (driverId) => {
    return store.dispatch('freeKwhPackages/getFreeKWh', [driverId])
}
const getFreeKWhPerDriver = async () => {
    return store.dispatch('freeKwhPackages/getFreeKWhPerDriver')
}
const startChargingForm = (value) => {
    state.showChargingForm = value
}
const goBack = () => {
    commit('drivers/setDriver', getDefaultState().driver)
    router.push({ path: '/drivers' })
}
const resolveYes = () => {
    state.showDialogBox = false
    commit('drivers/toggleDriverEdit', false)
    if (state.navigateTo !== '') {
        router.push({ path: state.navigateTo })
        state.navigateTo = ''
    }
}
const resolveNo = () => {
    state.showDialogBox = false
    commit('drivers/toggleDriverEdit', true)
}

onMounted(async () => {
    if (state.driverId) {
        await getEshopProducts(state.driverId)
        state.packages = await getFreeKWh(state.driverId)
    } else {
        state.packages = await getFreeKWhPerDriver()
    }
})

onBeforeMount(async () => {
    state.driverId = parseInt(route.params.id)
    await dispatch('drivers/getDriver', state.driverId)
    await dispatch('cars/getCars')
    // UX in mobile just scroll to the top so user is not in the middle of the view...
    if (isMobileView.value) {
        window.scrollTo(0, 0)
    }
})

onBeforeRouteLeave((to) => {
    if (store.state.drivers.isEditView) {
        state.showDialogBox = true
        state.navigateTo = to.path
        return false
    }
})
</script>

<template>
    <div class="driver-details-wrapper">
        <div v-if="driver.id" class="driver">
            <BtnGoBack @goBack="goBack" :skip-go="true" />
            <v-row>
                <v-col :md="driver && driver.freeKWh.freekwh_amount > 0 ? 9 : 12" cols="12">
                    <DriverInfo
                        :id="parseInt(driver.id)"
                        :driver="driver"
                        @showChargingForm="startChargingForm"
                        @showPrompt="state.showDialogBox = true"
                    />
                </v-col>
                <v-col v-if="driver && driver.freeKWh.freekwh_amount > 0" cols="12" md="3">
                    <FreeKWh />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-3" cols="12" md="7">
                    <PriceProgram :driver="driver" @refetch="refetchDriver" />
                </v-col>
                <v-col class="mt-3" cols="12" md="5">
                    <ChargingCard v-if="driver.id" :driver-id="parseInt(driver.id)" />
                </v-col>
            </v-row>
            <ChargingForm
                v-if="state.showChargingForm"
                :driver="parseInt(driver.id)"
                @close="state.showChargingForm = false"
            />
            <TariffChanges />
            <section v-if="driver" :class="driver && driver.vehicle.id ? 'mb-0' : ''" class="section mt-5">
                <VehicleDetails :driver="driver" />
            </section>
            <section class="section">
                <Sessions
                    :driver-id="[driver.id]"
                    :hide-details="true"
                    :is-driver-detail="true"
                    :is-header="false"
                    :show-sessions-filter="true"
                />
            </section>
            <Modal :is-active="state.isPopupOrderCard" :max-width="600" @cancel="state.isPopupOrderCard = false">
                <v-form ref="form" class="pa-4">
                    <h2 class="section__title mb-2">
                        {{ t('orderCard.title') }}
                    </h2>
                    <v-row class="my-4">
                        <v-col cols="12">
                            <span>
                                {{ t('orderCard.description', { price: getRfidPrice() }) }}
                            </span>
                        </v-col>
                    </v-row>
                    <div class="d-flex justify-end">
                        <Btn :loading="!!state.isLoading" class="mr-2" @click="state.isPopupOrderCard = false">
                            {{ t('addVehicle.cancel') }}
                        </Btn>
                        <Btn :loading="!!state.isLoading" @click="requestOrderCard()">
                            {{ t('orderCard.orderCard') }}
                        </Btn>
                    </div>
                </v-form>
            </Modal>
            <Modal :is-active="state.showDialogBox" :max-width="275">
                <div class="modal">
                    <div class="pa-5">
                        <h4 class="py-5 mb-5 text-center">
                            {{ t('error.HasChanges') }}
                        </h4>
                        <div class="d-flex justify-space-around">
                            <Btn class="modal__btn" medium data-testid="confirm" @click="resolveNo()">
                                {{ t('oAuth.cancel') }}
                            </Btn>
                            <Btn class="modal__btn active-link active-button" medium text data-testid="confirm" @click="resolveYes()">
                                {{ t('BtnGoBack.ok') }}
                            </Btn>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
        <div v-else class="driver-detail-spinner fixed d-flex align-center justify-center">
            <v-progress-circular :size="50" color="primary" indeterminate />
        </div>
    </div>
</template>
